<template>
  <div class="about" @mousemove="handleMouseMove">
    <NavbarComp/>
    <h2>About</h2>
    <div class="content">
      <div class="content_main-pic"/>
      <p>
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
      </p>
    </div>
    <img class="stars4" :style="imageStyle4" src="@/assets/stars4.png" alt="stars">
  </div>
</template>

<script setup lang="ts">
import NavbarComp from "@/components/NavbarComp.vue";
import {ref} from "vue";

const imageStyle4 = ref({ transform: 'translate(0, 0)' });

const handleMouseMove = (event: { clientX: any; clientY: any; }) => {
  const mouseX = event.clientX;
  const mouseY = event.clientY;

  const speed = 0.05;

  const x4 = (window.innerWidth / 2 - mouseX) * speed;
  const y4 = (window.innerHeight / 2 - mouseY) * speed;

  const centerX = window.innerWidth / 2;
  const centerY = window.innerHeight / 2;

  imageStyle4.value = { transform: `translate(${x4}px, ${y4}px)` };
};
</script>

<style scoped lang="sass">
.stars4
  position: absolute
  top: 20%
  width: 160px
  right: 15%
  transition: transform 0.3s linear
  z-index: 1
.about
  width: 100%
  height: 100vh
  background-image: url("@/assets/about-bg1.png")
  background-attachment: fixed
  background-size: cover
  position: relative
  padding-top: 120px
  display: flex
  align-items: center
  flex-direction: column
  gap: 80px
  h2
    text-align: center
    transform-style: preserve-3d
    font-weight: 900
    font-size: clamp(60px, 8vw, 150px)
    font-family: "y2k", monospace
    color: #f7d640
    text-shadow: -2px -2px 0 #ff2a4b, 0 0 10px rgba(255, 42, 75, 0.99), 0 0 20px rgba(255, 42, 75, 0.8), 0 0 30px rgba(255, 42, 75, 0.6), 0 0 40px rgba(255, 42, 75, 0.4), 0 0 50px rgba(255, 42, 75, 0.2)
    animation: text-color-change 10s linear infinite
.content
  width: 100%
  height: 100%
  display: flex
  align-items: flex-start
  justify-content: center
  gap: 20px
  z-index: 5
  &_main-pic
    min-width: 300px
    width: 25%
    aspect-ratio: 1 / 1
    background-image: url("@/assets/about.png")
    border: 4px solid #1a1a1a
    border-radius: 5px
    background-size: cover
    background-position: center
    position: relative
    &_pic
      position: absolute
      width: 90%
      bottom: 0
      left: 50%
      transform: translateX(-50%)
  & p
    width: 25%
    height: 25%
    font-size: clamp(17px, 3vw, 25px)
    font-family: "Raleway", sans-serif
    font-weight: 100
    padding: 5px 10px
    border-radius: 5px
    //background-color: #464646
    //box-shadow: 0 0 10px #464646, 0 0 20px rgba(70, 70, 70, 0.8), 0 0 30px rgba(70, 70, 70, 0.6), 0 0 40px rgba(70, 70, 70, 0.4), 0 0 50px rgba(70, 70, 70, 0.2)
    //text-shadow: -1px -1px 0 #000
header, footer
  font-family: "Syne Mono", monospace
  padding: 20px 40px
  font-size: 25pt
  font-weight: 700
  display: flex
  align-items: center
  justify-content: space-between
  height: 100px
  width: 100%
header
  position: absolute
  top: 0
footer
  position: absolute
  bottom: 0
.link
  cursor: pointer
  transition: all 0.3s ease
  color: #f7d640
  text-shadow: -1px -1px 0 #000
  &:hover
    color: white
    background-color: #ff2a4b
    border-radius: 5px
    padding: 5px 10px
    box-shadow: -2px -2px 0 #ff2a4b, 0 0 10px rgba(255, 42, 75, 0.99), 0 0 20px rgba(255, 42, 75, 0.8), 0 0 30px rgba(255, 42, 75, 0.6), 0 0 40px rgba(255, 42, 75, 0.4), 0 0 50px rgba(255, 42, 75, 0.2)
.link.hovered
  color: #676767
  text-shadow: none
@media (max-width: 430px)
  .about
    background-attachment: scroll
  header, footer
    padding: 10px 20px
  .content
    &_ca
      font-size: 11pt
</style>