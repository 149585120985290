<template>
  <div class="gallery-view">
    <h1>Gallery</h1>
    <div class="content">
      <div class="gallery">
        <div v-for="(line, lineIndex) in randomLines" :key="lineIndex" class="gallery-line">
          <div v-for="(image, imageIndex) in line" :key="imageIndex" :class="['gallery-item', image.type]">
            <img :src="image.src" :alt="'Image ' + (lineIndex * 4 + imageIndex + 1)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';

const images = ref([
  { src: require('@/assets/memes/meme1.png') },
  { src: require('@/assets/memes/meme2.png') },
  { src: require('@/assets/memes/meme3.png') },
  { src: require('@/assets/memes/meme4.png') },
  { src: require('@/assets/memes/meme5.png') },
  { src: require('@/assets/memes/meme6.png') },
  { src: require('@/assets/memes/meme7.png') },
  { src: require('@/assets/memes/meme8.png') },
  { src: require('@/assets/memes/meme9.png') },
  { src: require('@/assets/memes/meme10.png') },
  { src: require('@/assets/memes/meme11.png') },
  { src: require('@/assets/memes/meme12.png') },
]);

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const randomLines = computed(() => {
  const shuffledImages = shuffleArray([...images.value]);
  const lines = [];

  for (let i = 0; i < shuffledImages.length; i += 4) {
    const line = shuffledImages.slice(i, i + 4);
    const randomOrder = shuffleArray(['wide', 'narrow', 'wide', 'narrow']);

    const randomLine = line.map((image, index) => ({
      ...image,
      type: randomOrder[index],
    }));

    lines.push(randomLine);
  }

  return lines;
});
</script>

<style scoped lang="sass">
.gallery-view
  width: 100%
  background-image: url("@/assets/gallery-bg.jpg")
  background-attachment: fixed
  background-size: cover
  position: relative
  padding-top: 80px
  padding-bottom: 260px
  display: flex
  align-items: center
  flex-direction: column
  gap: 40px
  h1
    text-align: center
    font-weight: 900
    font-size: clamp(60px, 8vw, 150px)
    font-family: "y2k", monospace
    color: #f7d640
    text-shadow: -2px -2px 0 #ff2a4b, 0 0 10px rgba(255, 42, 75, 0.99), 0 0 20px rgba(255, 42, 75, 0.8), 0 0 30px rgba(255, 42, 75, 0.6), 0 0 40px rgba(255, 42, 75, 0.4), 0 0 50px rgba(255, 42, 75, 0.2)
    animation: text-color-change 10s linear infinite
.content
  width: 90%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  z-index: 5
.gallery
  display: flex
  flex-direction: column
  gap: 5px
.gallery-line
  display: grid
  grid-template-columns: repeat(6, 1fr)
  gap: 5px
.gallery-item
  border-radius: 5px
  overflow: hidden
  position: relative
  transition: all 0.3s ease
  border: 4px solid #1e1d1d
  &:hover
    z-index: 10
    scale: 1.1
    rotate: -5deg
  &.wide
    grid-column: span 2
    aspect-ratio: 2 / 1
  &.narrow
    grid-column: span 1
    aspect-ratio: 1 / 1
  img
    width: 100%
    height: 100%
    object-fit: cover
    display: block

@media (max-width: 760px)
  .gallery-line
    grid-template-columns: repeat(4, 1fr)
@media (max-width: 430px)
  .gallery-view
    background-attachment: scroll
</style>