<template>
  <div style="border: 4px solid #1a1a1a">
    <div class="separator">
      <p class="text" v-for="i in 100" :key="i">
        $LUNA
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="sass">
.separator
  font-weight: 700
  height: 80px
  background-color: #252525
  display: flex
  align-items: center
  overflow: hidden
  white-space: nowrap
  width: 100vw
.text
  //color: #f7d640
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.8), 0 0 10px rgba(255, 255, 255, 0.6), 0 0 15px rgba(255, 42, 75, 0.4), 0 0 20px rgba(255, 42, 75, 0.2)
  display: inline-block
  animation: news-ticker 30s linear alternate infinite
  font-size: 35pt
  margin-right: 50px
@keyframes news-ticker
  0%
    transform: translateX(10%)
  100%
    transform: translateX(-1000%)
</style>