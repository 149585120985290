<template>
  <div class="app">
    <MainView/>
    <Separator/>
    <AboutView/>
    <Separator/>
    <GalleryView/>
    <FooterView/>
  </div>
</template>

<script setup lang="ts">
import {onMounted} from "vue";
import MainView from "@/views/MainView.vue";
import Separator from "@/components/Separator.vue";
import AboutView from "@/views/AboutView.vue";
import NavbarComp from "@/components/NavbarComp.vue";
import GalleryView from "@/views/GalleryView.vue";
import FooterView from "@/views/FooterView.vue";

function copyText(text: string) {
  navigator.clipboard.writeText(text).then(() => {
  }).catch(err => {
    console.error(err);
  });
}
onMounted(() => {
  const links = document.querySelectorAll('.link');

  links.forEach(link => {
    link.addEventListener('mouseenter', () => {
      links.forEach(l => {
        if (l !== link) {
          l.classList.add('hovered');
        }
      });
    });
    link.addEventListener('mouseleave', () => {
      links.forEach(l => {
        l.classList.remove('hovered');
      });
    });
  });
})
</script>

<style lang="sass">
*
  margin: 0
  padding: 0
  box-sizing: border-box
a
  text-decoration: none
  color: inherit
.app
  width: 100vw
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  color: white
  position: relative
  overflow-x: hidden
  font-family: "Syne Mono", monospace
body
  background-color: black
  overflow-x: hidden
</style>