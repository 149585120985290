<template>
  <footer>
    <div class="content">
      <h2>Lunaria</h2>
      <span>2024</span>
      <div class="links">
        <svg class="links_link" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 12 12" fill="none"><path d="M5.92039 1.3088C6.99879 0.230399 8.74723 0.230399 9.82569 1.3088C10.904 2.38721 10.904 4.13565 9.82569 5.21405L7.873 7.16667L3.96777 3.26142L5.92039 1.3088Z" fill="#fff"></path><path d="M5.34857 9.69375C4.27017 10.7722 2.52173 10.7722 1.44333 9.69375C0.36492 8.61537 0.36492 6.86694 1.44333 5.78854L3.39596 3.83594L7.30119 7.74116L5.34857 9.69375Z" fill="#fff"></path></svg>
        <svg class="links_link" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 12 12" fill="none"><path d="M9.282 1H10.992L7.255 5.27L11.649 11.079H8.21L5.515 7.555L2.43 11.08H0.721L4.716 6.513L0.5 1H4.028L6.464 4.22L9.282 1ZM8.682 10.056H9.629L3.513 1.97H2.497L8.682 10.056Z" fill="#fff"></path></svg>
        <svg class="links_link" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 13.5 13.5" fill="none"><g clip-path="url(#clip0_12893_33676)"><mask id="mask0_12893_33676" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12" style="mask-type: luminance;"><path d="M12 0H0V12H12V0Z" fill="white"></path></mask><g mask="url(#mask0_12893_33676)"><path d="M11.8939 1.90992L10.0939 10.3969C9.9599 10.9969 9.6039 11.1429 9.1019 10.8619L6.3599 8.84192L5.0379 10.1149C4.8909 10.2619 4.7679 10.3849 4.4869 10.3849L4.6829 7.59192L9.7639 2.99992C9.9839 2.80392 9.7139 2.69292 9.4199 2.88992L3.1379 6.84392L0.429897 5.99992C-0.158103 5.81692 -0.170103 5.41192 0.551897 5.13092L11.1339 1.05292C11.6239 0.869924 12.0519 1.16292 11.8939 1.90992Z" fill="#fff"></path></g></g><defs><clipPath id="clip0_12893_33676"><rect width="12" height="12" fill="white"></rect></clipPath></defs></svg>
        <svg class="links_link" width="38" height="38" viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg" data-v-2db376de="">
          <g fill="white" transform="translate(30, 0)" data-v-2db376de="">
            <path d="M151.818 106.866c9.177-4.576 20.854-11.312 32.545-20.541 2.465 5.119 2.735 9.586 1.465 13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 77.204 12.083 85.664 8.406 97.882 3.103 111.959 .377 126 0m-25.818 106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197" data-v-2db376de=""></path>
            <path d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 41.862-100.816a41.865 41.865 0 015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 8.193-8.399 10.153-13.953l-2.01-4.175z" data-v-2db376de=""></path>
          </g>
        </svg>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
</script>

<style scoped lang="sass">
footer
  width: 100vw
  height: 200px
  background-image: url("@/assets/bg1.png")
  background-size: contain
  display: flex
  align-items: center
  justify-content: space-between
  padding: 40px 100px
  overflow: hidden
  position: absolute
  bottom: 0
  clip-path: polygon(0 0, 100% 40px, 100% 100%, 0 100%)
  & h2
    transition: transform 0.3s linear
    transform-style: preserve-3d
    text-align: center
    font-weight: 900
    font-size: clamp(90px, 10vw, 100px)
    font-family: "y2k", monospace
    position: relative
    color: transparent
    text-outline: white 5px 5px
    text-shadow: none
    -webkit-text-stroke-width: 0.5px
    -webkit-text-stroke-color: white
    &::after
      color: white
      position: absolute
      right: -10%
      top: -10%
      content: '$LUNA'
      font-family: "Syne Mono", monospace
      font-size: clamp(17px, 5vw, 23px)
  & span
    position: absolute
    bottom: 40px
    left: 50%
    transform: translateX(-50%)
    font-size: clamp(17px, 10vw, 55px)
    &::after
      position: absolute
      right: -100%
      bottom: 13px
      content: "$LUNA cat coin"
      font-size: clamp(5px, 5vw, 15px)
.links
  display: flex
  align-items: center
  gap: 40px
  &_link
    transition: all 0.3s ease
    scale: 1.2
    cursor: pointer
    padding: 7px
    border-radius: 50%
    &:hover
      background-color: #ff2a4b
      box-shadow: 0 0 10px rgba(255, 42, 75, 0.99), 0 0 20px rgba(255, 42, 75, 0.8), 0 0 30px rgba(255, 42, 75, 0.6), 0 0 40px rgba(255, 42, 75, 0.4), 0 0 50px rgba(255, 42, 75, 0.2)
.content
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
</style>
