<template>
  <div class="main" @mousemove="handleMouseMove" >
    <header>
      <a href="https://x.com/lunariacat" target="_blank">
        <span class="link">"TWITTER"</span>
      </a>
      <a href="https://x.com/lunariacat" target="_blank">
        <span class="link">"TELEGRAM"</span>
      </a>
    </header>
    <div class="content">
      <h1 :style="textStyle">
        "Lunaria
        <span class="title1" :style="textStyle">"Lunaria"</span>
        <span class="title2" :style="textStyle">"Lunaria"</span>
        <span class="title3" :style="textStyle">"Lunaria"</span>
      </h1>
      <div class="content_main-pic">
        <img class="content_main-pic_pic" src="@/assets/luna.png" alt="luna">
      </div>
      <span class="ca">{{ "CA coming soon...".toUpperCase() }}</span>
    </div>
    <footer>
      <a href="https://x.com/lunariacat" target="_blank">
        <span class="link">"PUMP.FUN"</span>
      </a>
      <a href="https://x.com/lunariacat" target="_blank">
        <span class="link">"DEXSCREENER"</span>
      </a>
    </footer>
    <img class="stars" :style="imageStyle1" src="@/assets/stars.png" alt="stars">
    <img class="stars2" :style="imageStyle2" src="@/assets/stars2.png" alt="stars">
    <img class="stars3" :style="imageStyle3" src="@/assets/stars3.png" alt="stars">
  </div>
</template>

<script setup lang="ts">
import {ref} from "vue";

const textStyle = ref({ transform: 'rotate(0deg)' });

const imageStyle1 = ref({ transform: 'translate(0, 0)' });
const imageStyle2 = ref({ transform: 'translate(0, 0)' });
const imageStyle3 = ref({ transform: 'translate(0, 0)' });

const handleMouseMove = (event: { clientX: any; clientY: any; }) => {
  const mouseX = event.clientX;
  const mouseY = event.clientY;

  const speed = 0.05;
  const x1 = (window.innerWidth / 2 - mouseX) * speed;
  const y1 = (window.innerHeight / 2 - mouseY) * speed;

  const x2 = (window.innerWidth / 2 - mouseX) * speed * 5;
  const y2 = (window.innerHeight / 2 - mouseY) * speed * 2;

  const x3 = (window.innerWidth / 2 - mouseX) * speed * 2;
  const y3 = (window.innerHeight / 2 - mouseY) * speed * 5;

  const centerX = window.innerWidth / 2;
  const centerY = window.innerHeight / 2;

  const deltaX = mouseX - centerX;
  const deltaY = mouseY - centerY;

  const angleX = (deltaY / centerY) * -15; // Изменение угла по X
  const angleY = (deltaX / centerX) * 15; // Изменение угла по Y

  textStyle.value = { transform: `rotateX(${angleX}deg) rotateY(${angleY}deg)` };

  imageStyle1.value = { transform: `translate(${x1}px, ${y1}px)` };
  imageStyle2.value = { transform: `translate(${x2}px, ${y2}px)` };
  imageStyle3.value = { transform: `translate(${x3}px, ${y3}px)` };
};
</script>

<style scoped lang="sass">
.main
  width: 100%
  height: 100vh
  background-image: url("@/assets/grainy-bg.jpg")
  background-attachment: fixed
  background-size: cover
  position: relative
  overflow: hidden
.stars
  position: absolute
  top: 30%
  width: 300px
  left: 5%
  transition: transform 0.3s linear
  z-index: 1
.stars2
  position: absolute
  top: 60%
  width: 150px
  right: 15%
  transition: transform 0.3s linear
  z-index: 1
.stars3
  position: absolute
  top: 20%
  width: 150px
  right: 10%
  transition: transform 0.3s linear
  z-index: 1
.content
  position: relative
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  padding: 60px
  perspective: 1000px
  z-index: 2
  &_main-pic
    position: absolute
    top: 60%
    left: 50%
    transform: translate(-50%, -50%)
    min-width: 280px
    width: 20%
    aspect-ratio: 1 / 1
    background-image: url("@/assets/gif-bg.gif")
    background-size: cover
    background-position: center
    border-radius: 5px
    &_pic
      position: absolute
      width: 90%
      bottom: 0
      left: 50%
      transform: translateX(-50%)
  h1
    transition: transform 0.3s linear
    transform-style: preserve-3d
    text-align: center
    font-weight: 900
    font-size: clamp(90px, 17vw, 210px)
    font-family: "y2k", monospace
    color: #f7d640
    text-shadow: -2px -2px 0 #ff2a4b, 0 0 10px rgba(255, 42, 75, 0.99), 0 0 20px rgba(255, 42, 75, 0.8), 0 0 30px rgba(255, 42, 75, 0.6), 0 0 40px rgba(255, 42, 75, 0.4), 0 0 50px rgba(255, 42, 75, 0.2)
    position: relative
    &::after
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.99), 0 0 20px rgba(255, 42, 75, 0.8), 0 0 30px rgba(255, 42, 75, 0.6), 0 0 40px rgba(255, 42, 75, 0.4), 0 0 50px rgba(255, 42, 75, 0.2)
      color: white
      position: absolute
      right: -10%
      top: -10%
      content: '$LUNA'
      font-family: "Syne Mono", monospace
      font-size: clamp(17px, 5vw, 45px)
.ca
  font-size: clamp(17px, 3vw, 25px)
  font-family: "Syne Mono", monospace
  font-weight: 300
  padding: 5px 10px
  border-radius: 5px
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.99), 0 0 10px rgba(255, 42, 75, 0.8), 0 0 15px rgba(255, 42, 75, 0.6), 0 0 20px rgba(255, 42, 75, 0.4), 0 0 25px rgba(255, 42, 75, 0.2)
  color: white
  z-index: 10
.title1
  transform-style: preserve-3d
  content: '"Lunaria'
  position: absolute
  left: 0
  bottom: -90%
  color: transparent
  text-outline: white 5px 5px
  text-shadow: none
  -webkit-text-stroke-width: 1.5px
  -webkit-text-stroke-color: white
.title2
  transform-style: preserve-3d
  content: '"Lunaria'
  position: absolute
  left: 0
  bottom: -190%
  color: transparent
  text-outline: white 5px 5px
  text-shadow: none
  -webkit-text-stroke-width: 1.5px
  -webkit-text-stroke-color: white
.title3
  transform-style: preserve-3d
  content: '"Lunaria'
  position: absolute
  left: 0
  bottom: -290%
  color: transparent
  text-outline: white 5px 5px
  text-shadow: none
  -webkit-text-stroke-width: 1.5px
  -webkit-text-stroke-color: white
header, footer
  font-family: "Syne Mono", monospace
  padding: 20px 40px
  font-size: clamp(25px, 2vw, 55px)
  font-weight: 700
  display: flex
  align-items: center
  justify-content: space-between
  height: 100px
  width: 100%
  z-index: 3
header
  position: absolute
  top: 0
footer
  position: absolute
  bottom: 0
.link
  cursor: pointer
  transition: all 0.3s ease
  color: #f7d640
  text-shadow: -1px -1px 0 #000
  &:hover
    color: white
    background-color: #ff2a4b
    border-radius: 5px
    padding: 5px 10px
    box-shadow: -2px -2px 0 #ff2a4b, 0 0 10px rgba(255, 42, 75, 0.99), 0 0 20px rgba(255, 42, 75, 0.8), 0 0 30px rgba(255, 42, 75, 0.6), 0 0 40px rgba(255, 42, 75, 0.4), 0 0 50px rgba(255, 42, 75, 0.2)
  &:active
    color: white
    background-color: #ff2a4b
    border-radius: 5px
    padding: 5px 10px
    box-shadow: -2px -2px 0 #ff2a4b, 0 0 10px rgba(255, 42, 75, 0.99), 0 0 20px rgba(255, 42, 75, 0.8), 0 0 30px rgba(255, 42, 75, 0.6), 0 0 40px rgba(255, 42, 75, 0.4), 0 0 50px rgba(255, 42, 75, 0.2)
.link.hovered
  color: #676767
  text-shadow: none
@media (max-width: 430px)
  .main
    background-attachment: scroll
  .stars
    top: 10%
    width: 150px
  .stars2
    top: 15%
    width: 150px
  .stars3
    top: unset
    bottom: 5%
    width: 150px
  header, footer
    padding: 10px 20px
  .content
    &_ca
      font-size: 11pt
  .title1
    bottom: -150%
  .title2
    bottom: -300%
  .title3
    bottom: -450%
  .ca
    margin-top: 150px
</style>
